import axios from "axios"
import api from "../api/BaseApi"
import {
  ON_START_LOADING,
  ON_FETCH_EXIST_ORDERS_SUCCESS,
  ON_FETCH_EXIST_ORDERS_FAIL,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_GET_ORDER_STATUSES_SUCCESS,
  ON_CLOSE_MESSAGE,
  ON_GET_ORDER_STATUSES_FAIL,
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_GET_ORDER_DETAILS_SUCCESS,
  CLEAR_ORDER_UPDATE,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
  CLEAR_GET_EXTERNAL_NUMBER,
  ON_UPDATE_ORDER_STATION_SUCCESS,
  ON_UPDATE_ORDER_STATION_FAIL,
  CLEAR_ORDER_STATION,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  CLEAR_ORDER_STATUS_UPDATE,
  ON_CREATE_ORDER_FROM_FILE_LOADING,
  ON_CREATE_ORDER_FROM_FILE_SUCCESS,
  ON_CREATE_ORDER_FROM_FILE_FAIL,
  CLEAR_CREATE_ORDER_FROM_FILE,
  ON_SEARCH_ORDERS_SUCCESS,
  ON_SEARCH_ORDERS_STOP,
  ON_SEARCH_ORDERS_FAIL,
  ON_SET_SEARCH_ORDERS,
  ON_CLEAR_SEARCH_ORDERS,
  ON_START_LOADING_NEW_ORDER,
  CLEAR_SEND_READY_IN_LOCKER_SMS,
  SEND_READY_IN_LOCKER_SMS_SUCCESS,
  SEND_READY_IN_LOCKER_SMS_FAIL,
  ON_TRANSFER_ORDER_SUCCESS,
  ON_TRANSFER_ORDER_FAIL,
  CLEAR_TRANSFER_ORDER,
} from "../actions/types"
import { axiosGetRetry } from "../utils/ReqsRetry"

export const onStartLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOADING,
    })
  }
}
export const onStartLoadingNewOrder = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOADING_NEW_ORDER,
    })
  }
}

export const clearOrderUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_UPDATE,
    })
  }
}

export const onFetchExistOrders = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.fetchOpenOrders, options)
      .then((res) => {
        dispatch({
          type: ON_FETCH_EXIST_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          dispatch({
            type: ON_FETCH_EXIST_ORDERS_FAIL,
            payload: {
              //errTitle: err.response.data.message,
              //errText: err.response.status
              //errTitle: 'err_title_text',     //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
            },
          })

          return
        }

        dispatch({
          type: ON_FETCH_EXIST_ORDERS_FAIL,
          payload: {
            //TODO
            //errTitle: err.response.data.message,
            //errText: err.response.status
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
          },
        })
      })
  }
}

export const onCreateOrder = (
  stationNumber,
  packageNumber,
  mobilePhone,
  firstName,
  lastName,
  externalOrderNumber
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    const body = [
      {
        stationNumber,
        packageNumber,
        mobilePhone,
        firstName,
        lastName,
        externalOrderNumber,
        orderStatus: -1,
      },
    ]

    axios
      .post(api.createOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_ORDER_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const setSelectedOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_TO_UPDATE,
      payload: order,
    })
  }
}

export const closeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_MESSAGE,
    })
  }
}

export const onUpdateOrder = (order) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const body = [
      {
        mobilePhone: order.mobilePhone,
        stationNumber: order.stationNumber,
        packageNumber: order.packageNumber,
        firstName: order.firstName,
        lastName: order.lastName,
        orderStatus: parseInt(order.orderStatus),
      },
    ]
    axios
      .post(api.updateOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_FAIL,
          payload: {
            errTitle: err?.response.data.message,
            errText: err?.response.status,
          },
        })
      })
  }
}

export const fetchOrderStatuses = () => {
  return (dispatch) => {
    axios
      .get(api.getOrderStatuses)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_FAIL,
          payload: err,
        })
      })
  }
}

export const fetchOrderTypes = () => {
  return (dispatch) => {
    axiosGetRetry(api.getOrderTypesByRole)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_FAILURE,
          payload: err,
        })
      })
  }
}

export const getOrderDetails = (orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })

    axios
      .get(api.getOrderDetails(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_DETAILS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const getExternalNumberByPackage = (packageNumber, orderType) => {
  return (dispatch) => {
    axios
      .get(api.getExternalNumberByPackage(packageNumber, orderType))
      .then((res) => {
        dispatch({
          type: ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
          payload: err,
        })
      })
  }
}

export const clearGetExternalNumber = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GET_EXTERNAL_NUMBER,
    })
  }
}

export const onUpdateOrderStation = (orderId, stationNumber) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .post(api.updateOrderStation(orderId, stationNumber), options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATION_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATION_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearOrderStationUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_STATION,
    })
  }
}

export const updateOrderStatusAndExtentionDate = (
  orderId,
  orderStatus,
  inLockerExtentionDate
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(
        api.updateOrderStatusAndExtentionDate(
          orderId,
          orderStatus,
          inLockerExtentionDate
        ),
        options
      )
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearOrderStatusUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_STATUS_UPDATE,
    })
  }
}

export const onCreateOrdersFromFile = (ordersFile) => {
  return (dispatch) => {
    dispatch({ type: ON_CREATE_ORDER_FROM_FILE_LOADING })
    const formData = new FormData()
    formData.append("ordersFile", ordersFile, ordersFile.name)
    axios
      .post(api.importOrdersFromCSV, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_FROM_FILE_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_ORDER_FROM_FILE_FAIL,
          payload: err,
        })
      })
  }
}

export const clearCreateOrdersFromFile = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CREATE_ORDER_FROM_FILE,
    })
  }
}

export const searchOrders = (data) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    if (data === undefined) {
      data = {
        fromDate: (new Date() - 3).toISOString(),
        toDate: new Date().toISOString(),
        page: 1,
        pageStep: 50,
      }
    }

    if (data.fromDate == null) {
      let fromDate = new Date()
      fromDate.setMonth(fromDate.getMonth() - 1)
      data.fromDate = fromDate.toISOString()
      data.page = 1
    }
    if (data.toDate == null) {
      data.toDate = new Date().toISOString()
      data.page = 1
    }

    data.station = data.station + ""
    if (data.station === "0") data.station = ""

    console.log("searchOrders data:")
    console.log(data)

    axios
      .post(api.searchOrders, data, options)
      .then((res) => {
        dispatch({
          type: ON_SEARCH_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              errText: err.response.status,
            },
          })

          return
        } else {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.message,
              errText: "שגיאה ",
            },
          })
        }
      })
  }
}

/*
DYNAMO
export const searchOrders = (data) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    if (data === undefined) {
      data = {
        fromDate: (new Date() - 3).toISOString(),
        toDate: new Date().toISOString(),
        page: 1,
        nextPageKey: null,
      }
    }

    if (data.fromDate == null) {
      let fromDate = new Date()
      fromDate.setMonth(fromDate.getMonth() - 1)
      data.fromDate = fromDate.toISOString()
      data.page = 1
      data.nextPageKey = null
    }
    if (data.toDate == null) {
      data.toDate = new Date().toISOString()
      data.page = 1
      data.nextPageKey = null
    }

    data.station = data.station + ""
    if (data.station === "0") data.station = ""

    if (data.page > 1 && data.nextPageKey === null) {
      console.log(
        "NO SEARCH!!!  page:" + data.page + " nextPageKey:" + data.nextPageKey
      )
      dispatch({ type: ON_SEARCH_ORDERS_STOP })
      return
    }

    if (data.page == 0) {
      data.page = 1
    }
    console.log("searchOrders data:")
    console.log(data)

    axios
      .post(api.searchOrders, data, options)
      .then((res) => {
        dispatch({
          type: ON_SEARCH_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              errText: err.response.status,
            },
          })

          return
        } else {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.message,
              errText: "שגיאה ",
            },
          })
        }
      })
  }
}*/

export const searchOrder = (data) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    const dataToSend = {
      accountType: 0,
      packageNumber: data.packageNumber,
    }

    console.log("searchOrder data:")
    console.log(dataToSend)

    axios
      .post(api.searchOrder, dataToSend, options)
      .then((res) => {
        dispatch({
          type: ON_SEARCH_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              errText: err.response.status,
            },
          })

          return
        } else {
          dispatch({
            type: ON_SEARCH_ORDERS_FAIL,
            payload: {
              errTitle: err.message,
              errText: "שגיאה ",
            },
          })
        }
      })
  }
}

export const setOrdersSearch = (data) => {
  return (dispatch) => {
    dispatch({
      type: ON_SET_SEARCH_ORDERS,
      payload: data,
    })
  }
}

export const clearOrdersSearch = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLEAR_SEARCH_ORDERS,
    })
  }
}

export const sendReadyInLockerSMS = (orderId) => {
  return (dispatch) => {
    axios
      .get(api.sendInLockerSMS(orderId))
      .then((res) => {
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_READY_IN_LOCKER_SMS_FAIL,
        })
      })
  }
}

export const clearSendReadyInLockerSMS = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEND_READY_IN_LOCKER_SMS,
    })
  }
}

export const onTransferOrder = (orderId, stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.onTransferOrder(orderId, stationId), options)
      .then((res) => {
        dispatch({
          type: ON_TRANSFER_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_TRANSFER_ORDER_FAIL,
        })
      })
  }
}

export const clearTransferOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TRANSFER_ORDER,
    })
  }
}
