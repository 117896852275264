import React, { Component } from "react"
import { connect } from "react-redux"

import {
  setOrdersSearch,
  clearOrdersSearch,
  fetchStationsByRole,
  fetchOrderStatuses,
  fetchOrderTypes,
} from "../../../actions"

import { InputDatePicker, SelectAutocomplete, Button } from "../../common"
import InputFiled from "../InputField"
import { isValidField } from "../../../utils/FieldsValidation"
import NotificationModal from "../NotificationModal"
import { fetchIfEmpty } from "../../../utils"
import "./style.scss"

const searchFromDateText = "מתאריך"
const searchToDateText = "עד תאריך"

class OrdersSearchFilterer extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      defaultStatus: -1,
      defaultStation: "",
      defaultOrderType: 0,
      defaultOrderNumber: "",
      defaultPackageNumber: "",
      defaultMobilePhone: "",
      defaultFirstName: "",
      defaultLastName: "",

      showDateCantBeNull: false,
      search: {
        orderNumber: "",
        packageNumber: null,
        mobilePhone: null,
        firstName: null,
        lastName: null,
        station: null,
        orderStatus: -1,
        orderType: 0,
        accountType: 0,
        fromDate: null,
        toDate: null,
        externalNumber: null,
        externalNumber2: null,
        pageStep: 50,
        page: 1,
        //nextPageKey: null,
      },

      searchData: {
        fromDate: null,
        toDate: null,
        orderNumber: "",
        packageNumber: "",
        mobilePhone: "",
        firstName: "",
        lastName: "",
      },

      validField: {
        orderNumber: {
          type: "orderNumber",
          isRequired: false,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 8,
          isValid: false,
        },
        packageNumber: {
          type: "packageNumber",
          isRequired: false,
          isChecked: false,
          compareMinValue: 3,
          compareMaxValue: 90,
          isValid: false,
        },
        mobilePhone: {
          type: "mobile",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        firstName: {
          type: "name",
          isRequired: false,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        lastName: {
          type: "name",
          isRequired: false,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
      },
    }

    this.state = { ...this.INIT_STATE }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.setDefaultsAndSearch = this.setDefaultsAndSearch.bind(this)
    this.cleanSearch = this.cleanSearch.bind(this)
  }

  componentDidMount() {
    fetchIfEmpty(this.props.stationSelectList, this.props.fetchStationsByRole)
    let { searchData } = this.props

    const isEmptySearch =
      JSON.stringify(searchData) === JSON.stringify(this.INIT_STATE.searchData)

    if (isEmptySearch) {
      console.log("Set fromDate if isEmptySearch.")
      searchData["fromDate"] = this.getDefaultFromDate()
      searchData["toDate"] = this.getDefaultToDate()
    }

    this.setDefaultsAndSearch(searchData)

    if (isEmptySearch) {
      console.log("Call setOrdersSearch if isEmptySearch.")
      this.props.setOrdersSearch(searchData)
    }

    this.searchClick()
  }

  componentWillMount() {}

  componentWillReceiveProps(newProps) {
    let { searchData } = newProps

    const isEmptySearch =
      JSON.stringify(searchData) === JSON.stringify(this.INIT_STATE.searchData)
    const isDataChanged =
      JSON.stringify(searchData) !== JSON.stringify(this.state.searchData)

    if (isEmptySearch) {
      searchData["fromDate"] = this.getDefaultFromDate()
      searchData["toDate"] = this.getDefaultToDate()
    }

    if (isDataChanged) {
      console.log("searchData is changed!!!")
      this.setDefaultsAndSearch(searchData)
    }

    if (isEmptySearch) {
      this.props.setOrdersSearch(searchData)
    }
    //TODO//ordersStatuses_SelectList,orderTypes_SelectList,stationSelectList
  }

  setDefaultsAndSearch(searchData) {
    const {
      stationSelectList,
      orderTypes_SelectList,
      ordersStatuses_SelectList,
    } = this.props

    if (searchData.fromDate == null) {
      searchData.fromDate = this.getDefaultFromDate()
    }

    if (searchData.toDate == null) {
      searchData.toDate = this.getDefaultToDate()
    }

    let defaultStatus = {
      label: "כל הסטטוסים",
      value: -1,
    }
    if (ordersStatuses_SelectList && ordersStatuses_SelectList.length > 0) {
      defaultStatus = ordersStatuses_SelectList.filter(
        (s) => s.value === searchData.orderStatus
      )
    } else {
      fetchIfEmpty(ordersStatuses_SelectList, this.props.fetchOrderStatuses)
    }

    let defaultOrderType = {
      label: "כל סוגים",
      value: 0,
    }
    if (orderTypes_SelectList && orderTypes_SelectList.length > 0) {
      defaultOrderType = orderTypes_SelectList.filter(
        (s) => s.value === searchData.orderType
      )
    } else {
      fetchIfEmpty(orderTypes_SelectList, this.props.fetchOrderTypes)
    }

    let defaultStation = {
      value: "",
      stationNumber: 0,
      label: "כל העמדות",
    }
    if (stationSelectList) {
      defaultStation = stationSelectList[0]
      if (searchData && searchData.station && searchData.station.length > 0) {
        defaultStation = stationSelectList.filter(
          (s) => s.stationNumber === searchData.station
        )[0]
      } else {
        fetchIfEmpty(
          this.props.stationSelectList,
          this.props.fetchStationsByRole
        )
      }
    }

    this.setState({
      searchData,
      defaultOrderType,
      defaultStatus,
      defaultStation,
    })
  }

  getDefaultFromDate() {
    let defaultDate = new Date()
    let newMonth = defaultDate.getMonth() - 3

    if (newMonth <= 0) {
      newMonth = newMonth <= 0 ? newMonth + 12 : newMonth
      defaultDate.setMonth(newMonth)
      defaultDate.setFullYear(defaultDate.getFullYear() - 1)
    } else {
      defaultDate.setMonth(newMonth)
    }

    return this.formatDateForPicker(defaultDate)
  }
  getDefaultToDate() {
    let defaultDate = new Date()
    return this.formatDateForPicker(defaultDate)
  }

  formatDateForPicker(date) {
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }

    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    return date.getFullYear() + "-" + month + "-" + day
  }

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) {
      return
    }

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field
    console.log(inputName + ": ")
    console.log(field)
    this.setState({
      ...this.state,
      validField,
    })
  }

  updateActiveType(item) {
    let { searchData, defaultOrderType } = this.state
    defaultOrderType = item
    searchData.orderType = item.value - 0

    searchData.page = 1
    //searchData.nextPageKey = null
    this.setState({ searchData, defaultOrderType })
    this.props.onSearchFilterChanged(searchData)
  }

  updateActiveStatus(item) {
    let { searchData, defaultStatus } = this.state
    defaultStatus = item
    if (
      searchData == undefined ||
      searchData.orderStatus == undefined ||
      item == undefined
    ) {
      return
    }
    searchData.orderStatus = item.value

    searchData.page = 1
    //searchData.pageKey = null
    this.setState({ searchData, defaultStatus })
    this.props.onSearchFilterChanged(searchData)
  }

  updateActiveStation(item) {
    let { searchData } = this.state

    searchData.station = item.stationNumber

    searchData.page = 1
    //searchData.nextPageKey = null

    this.setState({ searchData, defaultStation: item })
    this.props.onSearchFilterChanged(searchData)
  }

  updateActiveField(fieldName, value) {
    //TODO VALIDATION
    this.validateInputValue(fieldName, value)
    const { validField } = this.state
    let isValid = true

    if (validField[fieldName] !== undefined) {
      isValid = validField[fieldName].isChecked && validField[fieldName].isValid
    }

    //if (!isValid) return

    let { searchData } = this.state
    searchData[fieldName] = value
    searchData.page = 1
    //searchData.nextPageKey = null
    this.setState({ searchData })
    this.props.onSearchFilterChanged(searchData)
  }

  isValid(fieldName, value) {
    switch (fieldName) {
      case "orderNumber":
        break
    }
  }

  handleDateChange(inputName, event) {
    let { searchData } = this.state
    if (inputName === "fromDate" && event.target.value === "") {
      this.setState({ showDateCantBeNull: true })
      return
    }
    searchData[inputName] = event.target.value
    searchData.page = 1
    this.setState({ searchData })
    this.props.onSearchFilterChanged(searchData)
  }

  searchClick() {
    let { searchData } = this.state
    searchData.page = 1
    //searchData.nextPageKey = null
    this.setState({ searchData })
    this.props.onSearchFilterChanged(searchData)
    this.props.searchOrdersClick()
  }
  cleanSearch() {
    this.props.clearOrdersSearch()
    this.setState({
      defaultStatus: { label: "הכל", value: -1 },
      defaultOrderType: { label: "הכל", value: -1 },
      defaultStation: { label: "כל העמדות", value: "" },

      search: {
        orderNumber: "",
        packageNumber: "",
        mobilePhone: "",
        firstName: "",
        lastName: "",
        station: "",
        orderStatus: -1,
        orderType: 0,
        accountType: 0,
        fromDate: null,
        toDate: null,
        externalNumber: "",
        externalNumber2: "",
        pageStep: this.props.searchData.pageStep,
        page: 1,
        //nextPageKey: null,
      },
    })
  }

  render() {
    const {
      orderTypes_SelectList,
      ordersStatuses_SelectList,
      stationSelectList,
      isLoadingSearch,
    } = this.props

    const { searchData, validField } = this.state
    const {
      fromDate,
      toDate,
      orderNumber,
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
    } = searchData

    return (
      <>
        <div className="search-order-filterer ">
          <div className="line-wrapper">
            <InputDatePicker
              name="fromDate"
              date={fromDate}
              onChange={this.handleDateChange}
              placeholder={searchFromDateText}
            />

            <InputDatePicker
              name="toDate"
              date={toDate}
              onChange={this.handleDateChange}
              placeholder={searchToDateText}
            />

            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("orderNumber", e.target.value)
              }
              //onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי מספר הזמנה"
              value={orderNumber}
              //alt="ניתן לחפש הזמנה לפי נייד לקוח או מספר חבילה. ניתן לחפש לקוח לפי טלפון או שם."
              icon="magnifying_glass"
              isFocused={true}
              showValidation={validField["orderNumber"].isChecked}
              showValidationOk={validField["orderNumber"].isValid}
              showValidationError={!validField["orderNumber"].isValid}
              validationErrorMessage="המספר אמור להכיל עד 8 ספרות"
              maxLength={8}
            />
            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("packageNumber", e.target.value)
              }
              //onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי מספר חבילה"
              value={packageNumber}
              alt="ניתן לחפש הזמנה לפי מספר חבילה."
              icon="magnifying_glass"
              showValidation={validField["packageNumber"].isChecked}
              showValidationOk={validField["packageNumber"].isValid}
              showValidationError={!validField["packageNumber"].isValid}
              validationErrorMessage="אמור להכיל לפחות 3 מספרים ואותיות באנגלית"
              maxLength={30}
            />

            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("mobilePhone", e.target.value)
              }
              //onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי נייד"
              value={mobilePhone}
              alt="ניתן לחפש הזמנה לפי נייד לקוח בהזמנה ."
              icon="magnifying_glass"
              showValidation={validField["mobilePhone"].isChecked}
              showValidationOk={validField["mobilePhone"].isValid}
              showValidationError={!validField["mobilePhone"].isValid}
              validationErrorMessage="המספר אמור להכיל 10 ספרות"
              maxLength={20}
            />
          </div>

          <div className="line-wrapper">
            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("firstName", e.target.value)
              }
              //onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי שם פרטי"
              value={firstName}
              alt="ניתן לחפש הזמנה לפי שם פרטי."
              icon="magnifying_glass"
              showValidation={validField["firstName"].isChecked}
              showValidationOk={validField["firstName"].isValid}
              showValidationError={!validField["firstName"].isValid}
              validationErrorMessage="שם אמור להכיל אותיות "
              maxLength={30}
            />
            <InputFiled
              show
              onChange={(e) =>
                this.updateActiveField("lastName", e.target.value)
              }
              onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש לפי שם משפחה"
              value={lastName}
              alt="ניתן לחפש הזמנה לפי שם משפחה של לקוח."
              icon="magnifying_glass"
              showValidation={validField["lastName"].isChecked}
              showValidationOk={validField["lastName"].isValid}
              showValidationError={!validField["lastName"].isValid}
              validationErrorMessage="שם אמור להכיל אותיות "
              maxLength={30}
            />

            <SelectAutocomplete
              placeholder="סטטוס הזמנה"
              items={ordersStatuses_SelectList}
              value={this.state.defaultStatus}
              onChangeHandler={(itm) => this.updateActiveStatus(itm)}
            />

            <SelectAutocomplete
              placeholder="סוג הזמנה"
              items={orderTypes_SelectList}
              value={this.state.defaultOrderType}
              onChangeHandler={(itm) => this.updateActiveType(itm)}
              extraClassName="type-list"
            />

            <SelectAutocomplete
              placeholder="עמדה"
              items={stationSelectList}
              value={this.state.defaultStation}
              onChangeHandler={(itm) => this.updateActiveStation(itm)}
              extraClassName="sations-list"
            />
            {/**/}

            <div className="search-btn-wrapper">
              <Button
                extraClass={`${isLoadingSearch ? "disabled" : "search-btn"}`}
                onClick={(e) => this.searchClick()}
                tabIndex="0"
                disabled={isLoadingSearch}
              >
                חפש
              </Button>
            </div>
          </div>
        </div>
        <NotificationModal
          type={"warning"}
          title={"התאריך שמנסים לבחור לא תקין"}
          show={this.state.showDateCantBeNull}
          text={" אנא בחר תאריך תקין. לא נתן לרוקן את השדה."}
          onOkClick={() => this.setState({ showDateCantBeNull: false })}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  orderStatuses,
  station,
  searchOrders,
  orderTypes,
}) => {
  const { searchData, isLoadingSearch } = searchOrders
  const { ordersStatusesArr, ordersStatuses_SelectList } = orderStatuses
  const { orderTypesArr, orderTypes_SelectList } = orderTypes

  //const { stationSelectList, formattedStationsNames } =stationsList
  const { stations, stationSelectList, stationsLocalSearch } = station

  //const { selectedDecathlonStation } = station
  return {
    searchData,
    ordersStatusesArr,
    ordersStatuses_SelectList,
    orderTypesArr,
    orderTypes_SelectList,
    stations,
    stationSelectList,
    isLoadingSearch,
    //formattedStationsNames: selectedDecathlonStation,
  }
}

export default connect(mapStateToProps, {
  setOrdersSearch,
  clearOrdersSearch,
  fetchStationsByRole,
  fetchOrderStatuses,
  fetchOrderTypes,
})(OrdersSearchFilterer)
