import React from "react"
//import { BlueButton } from "../BlueButton"
import { Button } from "../Button"
import "./style.css"

const PaginationNext = ({ page, pageStep, currentResponseCount, onClick }) => {
  if (currentResponseCount <= 0 && page == 1) {
    return <></>
  }

  const renderNextButton = () => {
    if (currentResponseCount < pageStep) {
      return <></>
    }

    const nextPage = page + 1

    return (
      <Button
        extraClass="pagin-btn next-btn"
        onClick={(e) => onClick(nextPage)}
        key={nextPage}
      >
        דף הבא...
      </Button>
    )
  }

  let prePages = []
  for (let prePage = page - 1; prePage > 0; prePage--) {
    prePages[prePage] = prePage
  }

  return (
    <div className="pagin-next-wrapper">
      {prePages.map((pageItm) => {
        return (
          <Button
            extraClass="pagin-btn"
            onClick={(e) => onClick(pageItm)}
            key={pageItm}
          >
            {pageItm}
          </Button>
        )
      })}
      <Button
        extraClass="pagin-btn  pagin-selected"
        onClick={(e) => onClick(page)}
        key={page}
      >
        {page}
      </Button>
      {renderNextButton()}
    </div>
  )
}

export { PaginationNext }
