import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import edit_blue from "../../../../assets/svg/edit_blue.svg"
import "./style.scss"

const OrdersList = (props) => {
  let [data] = useState([])
  const [filterValue, setFilterValue] = useState("")
  const {
    orderList,
    onClick,
    onRelocateClick,
    ordersStatusesArr,
    userData,
    refreshTable,
    isLoadingSearch,
  } = props

  const formateOrder = (order) => {
    const {
      id,
      orderNumber,
      packageNumber,
      externalOrderNumber,
      externalOrderNumber2,
      mobilePhone,
      firstName,
      lastName,
      orderStatus,
      orderDate,
      branchDisplayName,
      createdByName,
      inLockerExtentionDate,
    } = order

    return {
      id,
      branchDisplayName,
      orderNumber,
      packageNumber,
      externalOrderNumber: setexternalOrderNumber(
        externalOrderNumber,
        externalOrderNumber2
      ),
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      createdByName,
      orderStatus: ordersStatusesArr[orderStatus],
      orderDate: getFormatedDate(orderDate),
      inLockerExtentionDate: getFormatedDate(inLockerExtentionDate),
    }
  }

  const setexternalOrderNumber = (
    externalOrderNumber,
    externalOrderNumber2
  ) => {
    if (externalOrderNumber != null) {
      return externalOrderNumber
    } else if (externalOrderNumber2 != null) {
      return externalOrderNumber2
    }
    return ""
  }

  const ordersByStation = () => {
    const { orderList, selectedDecathlonStation } = props
    if (orderList) {
      let filteredOrders
      if (selectedDecathlonStation) {
        filteredOrders = orderList.filter((order) => {
          return order.stationNumber == selectedDecathlonStation
        })
      } else {
        filteredOrders = orderList
      }

      return filteredOrders
        ? filteredOrders.map((order) => formateOrder(order))
        : []
    } else {
      return [filterValue]
    }
  }

  const clearFilterAndRefreshTable = () => {
    setFilterValue("")
    refreshTable()
  }

  // const formattedList = orderList  ? orderList.map((order) => formateOrder(order)) : []
  data = useMemo(() => ordersByStation(), [orderList])

  const columns = useMemo(() => {
    const result = [
      {
        Header: "תאריך",
        accessor: "orderDate",
      },
      {
        Header: "מספר חבילה",
        accessor: "packageNumber",
      },
      {
        Header: "טלפון",
        accessor: "mobilePhone",
      },
      {
        Header: "שם פרטי",
        accessor: "firstName",
      },
      {
        Header: "שם משפחה",
        accessor: "lastName",
      },
      {
        Header: "סטטוס",
        accessor: "orderStatus",
        Filter: SelectColumnFilter,
        filter: filterValue,
      },
      {
        Header: "נוצר על ידי",
        accessor: "createdByName",
        Filter: SelectColumnFilter,
        filter: filterValue,
      },
      {
        Header: "שם העמדה",
        accessor: "branchDisplayName",
      },
      {
        Header: "תאריך הארכה",
        accessor: "inLockerExtentionDate",
      },
    ]
    if (userData && userData.orderTypeGroup != "5") {
      result.splice(2, 0, {
        Header: "מספר הזמנה",
        accessor: "orderNumber",
      })
    }
    if (userData?.orderTypeGroup != "5" && userData.role != "TAU") {
      result.push({
        Header: "שטר מטען",
        accessor: "externalOrderNumber",
      })
    }
    if (userData) {
      result.splice(0, 0, {
        Header: () => null,
        id: "edit",
        width: 26,
        Cell: (props) => (
          <div className="order-edit">
            <img
              alt="edit_button"
              onClick={() => onClick(props.cell.row.original.orderNumber)}
              src={edit_blue}
            />
          </div>
        ),
      })
      result.splice(0, 0, {
        Header: () => null,
        id: "relocate",
        width: 26,
        Cell: ({ row }) => {
          const status = row.original.orderStatus
          const typeGroup = userData.orderTypeGroup
          return typeGroup != 1 &&
            (status == "מוכן בלוקר" || status == "סומן לחזרה לשולח") ? (
            <div
              className="fa fa-truck"
              onClick={() => onRelocateClick(row.original.orderNumber)}
            />
          ) : null
        },
      })
    }
    return result
  }, [])

  return (
    <div className="order-list-wrapper">
      <Container className="order-list">
        <TableContainer
          columns={columns}
          data={data}
          refreshTable={() => clearFilterAndRefreshTable()}
          filterValue={filterValue}
          resetFilterValue={() => setFilterValue("")}
          isLoadingSearch={isLoadingSearch}
          showTablePagination={true}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({ orders, orderStatuses, users, station }) => {
  const { orderList } = orders
  const { userData } = users
  const { selectedDecathlonStation } = station
  const { ordersStatusesArr } = orderStatuses
  return {
    orderList,
    ordersStatusesArr,
    userData,
    selectedDecathlonStation,
  }
}

export default connect(mapStateToProps)(OrdersList)
