import {
  ON_START_LOADING,
  ON_START_LOADING_NEW_ORDER,
  ON_FETCH_EXIST_ORDERS_SUCCESS,
  ON_FETCH_EXIST_ORDERS_FAIL,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_CLOSE_MESSAGE,
  RESET_ALL_REDUCERS,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_GET_ORDER_DETAILS_SUCCESS,
  CLEAR_ORDER_UPDATE,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
  ON_UPDATE_ORDER_STATION_SUCCESS,
  ON_UPDATE_ORDER_STATION_FAIL,
  CLEAR_ORDER_STATION,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  CLEAR_ORDER_STATUS_UPDATE,
  CLEAR_GET_EXTERNAL_NUMBER,
  ON_CREATE_ORDER_FROM_FILE_LOADING,
  ON_CREATE_ORDER_FROM_FILE_SUCCESS,
  ON_CREATE_ORDER_FROM_FILE_FAIL,
  CLEAR_CREATE_ORDER_FROM_FILE,
  SEND_READY_IN_LOCKER_SMS_SUCCESS,
  SEND_READY_IN_LOCKER_SMS_FAIL,
  CLEAR_SEND_READY_IN_LOCKER_SMS,
  ON_TRANSFER_ORDER_SUCCESS,
  ON_TRANSFER_ORDER_FAIL,
  CLEAR_TRANSFER_ORDER,
} from "../actions/types"

const INIT_STATE = () => {
  return {
    orderList: null,
    order: null,
    errTitle: null,
    errText: null,
    isLoading: false,
    isLoadingNewOrder: false,
    isError: false,
    showMessage: false,
    response: null,
    orderDetails: null,
    externalOrderNumber: null,
    orderStationUpdatedSuccess: false,
    orderStationUpdatedFail: false,
    orderStatusUpdateSuccess: false,
    orderStatusUpdateFail: false,
    getExternalOrderNumberSuccess: false,
    getExternalOrderNumberFail: false,
    createOrdersFromFileResponse: null,
    createOrdersFromFileFail: false,
    createOrdersFromFileSuccess: false,
    sendReadyInLockerSMSFail: false,
    sendReadyInLockerSMSSuccess: false,
    transferOrderSuccess: false,
    transferOrderFail: false,
  }
}

const OrderReducer = (state = INIT_STATE(), action) => {
  switch (action.type) {
    case ON_START_LOADING:
      return {
        ...INIT_STATE(),
        isLoading: true,
      }
    case ON_START_LOADING_NEW_ORDER:
      return {
        ...INIT_STATE(),
        isLoadingNewOrder: true,
      }

    case CLEAR_ORDER_UPDATE:
      return {
        ...state,
        order: null,
        errTitle: null,
        errText: null,
        isLoading: false,
        isLoadingNewOrder: false,
        isError: false,
        showMessage: false,
        response: null,
        orderDetails: null,
      }
    case ON_FETCH_EXIST_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.data,
        isLoading: false,
        showMessage: false,
        isError: false,
      }

    case ON_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        response: action.payload.data[0],
        isError: !action.payload.data[0].status,
        isLoading: false,
        isLoadingNewOrder: false,
        showMessage: true,
      }
    case SET_ORDER_TO_UPDATE:
      return {
        ...state,
        order: action.payload,
        showMessage: false,
        isError: false,
      }
    case ON_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        response: action.payload.data[0],
        isError: !action.payload.data[0].status,
        showMessage: true,
        isLoading: false,
      }
    case ON_FETCH_EXIST_ORDERS_FAIL:
    case ON_CREATE_ORDER_FAIL:
    case ON_UPDATE_ORDER_FAIL:
      return {
        ...state,
        isError: true,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        showMessage: true,
        isLoading: false,
        isLoadingNewOrder: false,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE(),
      }
    case ON_GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetails: null,
      }
    case ON_GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        isLoading: false,
        isLoadingNewOrder: false,
      }
    case ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS:
      return {
        ...state,
        externalOrderNumber: action.payload,
        getExternalOrderNumberSuccess: true,
        getExternalOrderNumberFail: false,
      }
    case ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL:
      return {
        ...state,
        externalOrderNumber: null,
        getExternalOrderNumberSuccess: false,
        getExternalOrderNumberFail: true,
      }
    case CLEAR_GET_EXTERNAL_NUMBER:
      return {
        ...state,
        externalOrderNumber: null,
        getExternalOrderNumberFail: false,
        getExternalOrderNumberSuccess: false,
      }
    case ON_UPDATE_ORDER_STATION_SUCCESS:
      return {
        ...state,
        orderStationUpdatedSuccess: true,
        orderStationUpdatedFail: false,
      }
    case ON_UPDATE_ORDER_STATION_FAIL:
      return {
        ...state,
        orderStationUpdatedSuccess: false,
        orderStationUpdatedFail: true,
      }
    case CLEAR_ORDER_STATION:
      return {
        ...state,
        orderStationUpdatedSuccess: false,
        orderStationUpdatedFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatusUpdateSuccess: true,
        orderStatusUpdateFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: true,
      }
    case CLEAR_ORDER_STATUS_UPDATE:
      return {
        ...state,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoadingNewOrder: true,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: false,
        isError: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingNewOrder: false,
        createOrdersFromFileResponse: action.payload,
        createOrdersFromFileSuccess: true,
        createOrdersFromFileFail: false,
        isError: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingNewOrder: false,
        createOrdersFromFileResponse: action.payload,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: true,
        isError: true,
      }
    case CLEAR_CREATE_ORDER_FROM_FILE:
      return {
        ...state,
        isLoading: false,
        isLoadingNewOrder: false,
        createOrdersFromFileResponse: null,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: false,
        isError: false,
      }
    case SEND_READY_IN_LOCKER_SMS_SUCCESS:
      return {
        ...state,
        sendReadyInLockerSMSSuccess: true,
        sendReadyInLockerSMSFail: false,
      }
    case SEND_READY_IN_LOCKER_SMS_FAIL:
      return {
        ...state,
        sendReadyInLockerSMSSuccess: false,
        sendReadyInLockerSMSFail: true,
      }
    case CLEAR_SEND_READY_IN_LOCKER_SMS:
      return {
        ...state,
        sendReadyInLockerSMSSuccess: false,
        sendReadyInLockerSMSFail: false,
      }
    case ON_TRANSFER_ORDER_SUCCESS:
      return {
        ...state,
        transferOrderSuccess: true,
        transferOrderFail: false,
      }
    case ON_TRANSFER_ORDER_FAIL:
      return {
        ...state,
        transferOrderSuccess: false,
        transferOrderFail: true,
      }
    case CLEAR_TRANSFER_ORDER:
      return {
        ...state,
        transferOrderSuccess: false,
        transferOrderFail: false,
      }
    default:
      return state
  }
}

export default OrderReducer
