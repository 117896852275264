import React, { useState, useEffect } from "react"
import Select from "react-select"
import "./transferOrderModal.scss"

const TransferOrderModal = ({
  stations,
  currentStationNumber,
  onSaveClick,
  onCloseClick,
}) => {
  const [selectedStation, setSelectedStation] = useState(null)

  useEffect(() => {
    const defaultStation = stations.find(
      (item) => item.value === currentStationNumber
    )
    setSelectedStation(defaultStation)
  }, [currentStationNumber, stations])

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="order-transfer-modal">
        <div>
          <h2>העברת הזמנה לעמדה אחרת</h2>
          <div className="current-station">
            <span>עמדה נוכחית: {currentStationNumber}</span>
          </div>
          <Select
            value={selectedStation}
            className="station-selector"
            placeholder="עמדה"
            options={stations}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(selectedStation) => {
              setSelectedStation(selectedStation)
            }}
          />
          <div className="btn-holder">
            <button onClick={() => onSaveClick(selectedStation?.stationId)}>
              שמור
            </button>
            <button onClick={onCloseClick}>סגור</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransferOrderModal
