import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
import {
  fetchAllUsers,
  setSelectedOrder,
  deleteUser,
  clearRequestData,
  resetPassword,
  resetBOPassword,
  addBOUser,
  RemoveBOUser,
} from "../../../actions"
import "./style.scss"
import UsersList from "./UsersList"
import YesNoModal from "../../common/YesNoModal"
import NotificationModal from "../../common/NotificationModal"

class UsersListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDeleteUserModal: false,
      showResetPasswordModal: false,
      showResetBOPasswordModal: false,
      userIdToDelete: null,
      userIdToResetPassowrd: null,
      boUserIdToResetPassowrd: null,
      showAddBOUserModal: false,
      showRemoveBOUserModal: false,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
      return
    }
    this.props.fetchAllUsers()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, userDeletedSuccess, isNeedReloadUsers } =
      newProps
    if (!authenticated) {
      history.push("/")
      return
    }
    if (userDeletedSuccess || isNeedReloadUsers) {
      this.props.clearRequestData()
      this.props.fetchAllUsers()
    }
  }

  onDeleteUserClick(userId) {
    this.setState({ showDeleteUserModal: true, userIdToDelete: userId })
  }

  onResetPasswordClick(userId) {
    this.setState({
      showResetPasswordModal: true,
      userIdToResetPassowrd: userId,
    })
  }

  onResetBOPasswordClick(boUserId) {
    this.setState({
      showResetBOPasswordModal: true,
      boUserIdToResetPassowrd: boUserId,
    })
  }

  onClickSetAsManager(userId) {
    this.setState({
      showAddBOUserModal: true,
      userIdToBOUser: userId,
    })
  }

  onClickRemoveFromManagerList(userId) {
    this.setState({
      showRemoveBOUserModal: true,

      userIdToBOUser: userId,
    })
  }

  onNewUserClick() {
    this.props.history.push("/NewUser")
  }

  confirmDeleteUser() {
    this.props.deleteUser(this.state.userIdToDelete)
    this.setState({ showDeleteUserModal: false })
  }

  confirmResetPassword() {
    this.props.resetPassword(this.state.userIdToResetPassowrd)
    this.setState({ showResetPasswordModal: false })
  }

  confirmResetBOPassword() {
    this.props.resetBOPassword(this.state.boUserIdToResetPassowrd)
    this.setState({ showResetBOPasswordModal: false })
  }

  confirmAddBOUser() {
    const { role, accountTypeId } = this.props
    this.props.addBOUser(this.state.userIdToBOUser, role, accountTypeId)
    this.setState({ showAddBOUserModal: false })
  }

  confirmRemoveBOUser() {
    const { role, accountTypeId } = this.props

    this.props.RemoveBOUser(this.state.userIdToBOUser, role, accountTypeId)
    this.setState({ showRemoveBOUserModal: false })
  }

  refreshTable() {
    this.props.fetchAllUsers()
  }

  render() {
    const {
      isLoading,
      userDeleteFail,
      isPasswordResetSucces,
      isPasswordResetFail,
    } = this.props
    const {
      showDeleteUserModal,
      showResetPasswordModal,
      showResetBOPasswordModal,
      showAddBOUserModal,
      showRemoveBOUserModal,
    } = this.state

    if (isLoading) {
      return (
        <div>
          <Title text="רשימת משתמשים" />
          <Loader show={isLoading} />
        </div>
      )
    }

    return (
      <div className="user-list">
        <Title text="רשימת משתמשים" />
        <div className="btn-new-user" onClick={() => this.onNewUserClick()}>
          יצירת משתמש חדש
        </div>
        <UsersList
          onClickDelete={(userId) => this.onDeleteUserClick(userId)}
          onClickResetPass={(userId) => this.onResetPasswordClick(userId)}
          onClickResetBOPass={(boUserId) =>
            this.onResetBOPasswordClick(boUserId)
          }
          onClickSetAsManager={(userId, role, accountTypeId) =>
            this.onClickSetAsManager(userId, role, accountTypeId)
          }
          onClickRemoveFromManagerList={(userId, role, accountTypeId) =>
            this.onClickRemoveFromManagerList(userId, role, accountTypeId)
          }
          refreshTable={() => this.refreshTable()}
          orderTypeGroup={this.props.orderTypeGroup}
        />
        <YesNoModal
          show={showDeleteUserModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך למחוק משתמש זה"}
          onYesClick={() => this.confirmDeleteUser()}
          onNoClick={() => this.setState({ showDeleteUserModal: false })}
        />
        <NotificationModal
          show={userDeleteFail}
          title={"ניהול משתמשים"}
          text={"כישלון במחיקת משתמש"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <NotificationModal
          show={isPasswordResetSucces}
          title={"ניהול משתמשים"}
          text={".איפוס סיסמה עבר בהצלחה, סיסמה חדשה נשלחה בסמס"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <NotificationModal
          show={isPasswordResetFail}
          title={"ניהול משתמשים"}
          text={"איפוס סיסמה נכשל"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <YesNoModal
          show={showResetPasswordModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך לאפס סיסמת בלדר למשתמש הזה"}
          onYesClick={() => this.confirmResetPassword()}
          onNoClick={() => this.setState({ showResetPasswordModal: false })}
        />
        <YesNoModal
          show={showResetBOPasswordModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך לאפס סיסמת מנהל למשתמש הזה"}
          onYesClick={() => this.confirmResetBOPassword()}
          onNoClick={() => this.setState({ showResetBOPasswordModal: false })}
        />
        <YesNoModal
          show={showAddBOUserModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך להוסיף את המשתמש לרשימת מנהלים"}
          onYesClick={() => this.confirmAddBOUser()}
          onNoClick={() => this.setState({ showAddBOUserModal: false })}
        />
        <YesNoModal
          show={showRemoveBOUserModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך להסיר את המשתמש מרשימת מנהלים"}
          onYesClick={() => this.confirmRemoveBOUser()}
          onNoClick={() => this.setState({ showRemoveBOUserModal: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const {
    authenticated,
    usersListArr,
    isLoading,
    userDeletedSuccess,
    userDeleteFail,
    isPasswordResetSucces,
    isPasswordResetFail,
    userData,
    isNeedReloadUsers,
  } = users

  const role = userData ? userData.role : null
  const accountType = userData ? userData.accountType : null
  const accountTypeId = userData ? userData.accountTypeId : null
  const orderTypeGroup = userData ? userData.orderTypeGroup : null

  return {
    authenticated,
    isLoading,
    usersListArr,
    userDeletedSuccess,
    userDeleteFail,
    isPasswordResetSucces,
    isPasswordResetFail,
    role,
    accountType,
    isNeedReloadUsers,
    accountTypeId,
    orderTypeGroup,
  }
}

export default connect(mapStateToProps, {
  fetchAllUsers,
  setSelectedOrder,
  deleteUser,
  resetPassword,
  resetBOPassword,
  clearRequestData,
  addBOUser,
  RemoveBOUser,
})(UsersListScreen)
