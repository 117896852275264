import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { fetchOrderTypes } from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import NotificationModal from "../../common/NotificationModal"
import { H1 } from "../../common/H1"
import { Button } from "../../common/Button"
import { Spinner } from "../../common/Spinner"
import api from "../../../api/BaseApi"
import axios from "axios"
import "./style.scss"

const GET_LOCKERS_NUMBERS = api.getLockersSelectOptions
const RELOCATE_ORDER = api.relocateOrder

const orderRelocationText = "שינוי מיקום הזמנה"
const stationText = "עמדה"
const lockerText = "תא"
const relocateButtonText = " העבר את ההזמנה לתא "

class RelocateOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lockersSelectOptions: [],
      lockerSelected: "",
      isSuccessful: false,
      isFailed: false,
      isLoading: false,
      order: null,
      validationMessage: "",
    }

    this.handleLockerSelectChange = this.handleLockerSelectChange.bind(this)
    this.handleStationLockersRequest =
      this.handleStationLockersRequest.bind(this)
    this.handleRelocateOrderRequest = this.handleRelocateOrderRequest.bind(this)
  }

  componentDidMount() {
    const { authenticated, history } = this.props

    if (!authenticated) {
      history.push("/")
    }

    fetchIfEmpty(
      this.props.lockerStatuses,
      this.props.fetchLockerStatusSelectOptions
    )
    this.getOrderDetails(this.props.order.id)
    fetchIfEmpty(this.props.stationsOptions, this.props.fetchAllStationData)
    fetchIfEmpty(this.props.orderTypesArr, this.props.fetchOrderTypes)
    this.setDefaultStation(this.state.order)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.order !== this.state.order) {
      this.setDefaultStation(this.state.order)
      this.handleStationLockersRequest()
    }
  }

  componentWillUnmount() {
    this.setState({
      lockersSelectOptions: [],
      stationSelected: "",
      lockerSelected: "",
      isSuccessful: false,
      isFailed: false,
      isLoading: false,
      order: null,
      validationMessage: "",
    })
  }

  setDefaultStation = (order) => {
    const { stationsOptions } = this.props
    if (order && stationsOptions) {
      const defaultStation = stationsOptions.find(
        (option) => option.stationId === order.stationId
      )

      if (defaultStation) {
        this.setState({ stationSelected: defaultStation })
      }
    }
  }

  getOrderDetails(orderId) {
    this.setState({ isLoading: true })
    axios
      .get(api.getOrderDetails(orderId))
      .then((res) => {
        this.setState({ order: res.data, isLoading: false })
      })
      .catch((err) => {
        console.log(err)

        this.setState({ isLoading: false })
      })
  }

  handleStationLockersRequest() {
    this.setState({ validationMessage: "" })
    const stationId = this.state.order.stationId

    const options = {
      method: "GET",
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    fetch(`${GET_LOCKERS_NUMBERS}/${stationId}`, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
      })
      .then((lockers) => {
        const lockersSelectOptions = Object.keys(lockers).map((key) => ({
          label: lockers[key],
          value: key,
          lockerId: key,
        }))

        this.setState({
          lockersSelectOptions,
        })
      })
      .catch((error) => {
        console.log("Error:")
        console.log(error)
      })
  }

  handleRelocateOrderRequest(event) {
    event.preventDefault()

    const { lockerSelected } = this.state

    if (lockerSelected == "") {
      this.setState({ validationMessage: "אנא בחר תא" })
      return
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }

    fetch(
      `${RELOCATE_ORDER}/${this.state.order?.id}?stationId=${this.state.order?.stationId}&lockerId=${lockerSelected.lockerId}`,
      options
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            isSuccessful: true,
          })
        } else {
          this.setState({
            isSuccessful: false,
            isFailed: true,
          })
        }
      })
      .catch((error) => {
        console.log(error.message)
        this.setState({
          isFailed: true,
        })
      })
  }

  handleLockerSelectChange(item) {
    this.setState({ lockerSelected: item, validationMessage: "" })
  }

  errorMsg() {
    return <div className="error-msg">מיקום הזמנה בלוקר נכשל.</div>
  }
  renderOrder(order) {
    const { isLoading } = this.state
    const { orderTypesArr, accountTypes } = this.props

    if (isLoading) {
      return (
        <div className="spinner-wrapper">
          <Spinner zoom={0.5} />
        </div>
      )
    }

    const {
      orderNumber,
      orderType,
      senderFullName,
      senderMobilePhone,
      customerFullName,
      mobilePhone,
      orderTotalPrice,
      accountType,
    } = order

    const isTwoCustomers = orderType === 5 || orderType === 8
    const customerName = isTwoCustomers ? "נמען:" : "שם לקוח בהזמנה:"
    const customerPhone = isTwoCustomers ? "טלפון נמען:" : "טלפון לקוח:"
    const orderTypeText = orderTypesArr
      ? orderTypesArr[order.orderType]
      : orderType
    const accountTypeText = isTwoCustomers
      ? accountTypes
        ? accountTypes[accountType]
        : accountType
      : null

    return (
      <>
        <div className="details-block">
          <div className="details-label">מספר הזמנה:</div>
          <div className="details-value">{orderNumber}</div>
        </div>
        <div className="details-block">
          <div className="details-label">סוג הזמנה:</div>
          <div className="details-value">{orderTypeText}</div>
        </div>
        {isTwoCustomers && (
          <div className="details-block">
            <div className="details-label">סוג החשבון:</div>
            <div className="details-value">{accountTypeText}</div>
          </div>
        )}
        <div className="details-block">
          <div className="details-label">{customerName}</div>
          <div className="details-value">{customerFullName}</div>
        </div>
        <div className="details-block">
          <div className="details-label">{customerPhone}</div>
          <div className="details-value">{mobilePhone}</div>
        </div>

        {isTwoCustomers && (
          <>
            <div className="details-block">
              <div className="details-label">שולח:</div>
              <div className="details-value">{senderFullName}</div>
            </div>
            <div className="details-block">
              <div className="details-label">טלפון שולח:</div>
              <div className="details-value">{senderMobilePhone}</div>
            </div>
          </>
        )}

        <div className="details-block">
          <div className="details-label">סכום הזמנה:</div>
          <div className="details-value">{orderTotalPrice} ₪</div>
        </div>
      </>
    )
  }

  render() {
    const {
      stationSelected,
      lockerSelected,
      lockersSelectOptions,
      isSuccessful,
      isFailed,
      isLoading,
      order,
      validationMessage,
    } = this.state
    const { stationsOptions } = this.props

    return (
      <div className="order-relocate-wrapper">
        <H1 title={orderRelocationText} />
        <div className="order-details">
          {order || isLoading ? this.renderOrder(order) : "ההזמנה לא נמצאה"}
        </div>
        <form
          className="form-inline"
          id="relocate-order-form"
          onSubmit={this.handleRelocateOrderRequest}
        >
          <br />
          <Select
            placeholder={lockerText}
            value={lockerSelected}
            options={lockersSelectOptions}
            onChange={(itm) => this.handleLockerSelectChange(itm)}
            classNamePrefix="base-select"
          />
          <div className="validation-message">{validationMessage}</div>
          <div className="btn-holder">
            <Button extraClass="btn-relocate" type="submit">
              {relocateButtonText +
                (lockerSelected ? lockerSelected.label : "")}
            </Button>
            <Button
              extraClass="btn-back"
              onClick={(e) => this.props.history.goBack()}
            >
              חזרה
            </Button>
          </div>
        </form>

        <NotificationModal
          show={isSuccessful}
          title={"עידכון מקום הזמנה"}
          text={"מיקום הזמנה בלוקר עודכן בהצלחה"}
          onOkClick={(e) => this.setState({ isSuccessful: false })}
        />
        <NotificationModal
          show={isFailed}
          title={"עידכון מקום נכשל"}
          text={this.errorMsg()}
          onOkClick={(e) => this.setState({ isFailed: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ orderTypes, users, orders }) => {
  const { order } = orders
  const { orderTypesArr } = orderTypes
  const { authenticated } = users

  return {
    authenticated,
    order,
    orderTypesArr,
  }
}

export default connect(mapStateToProps, {
  fetchOrderTypes,
})(RelocateOrderScreen)
